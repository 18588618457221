<template>
  <div>
    <v-select
      :items="steps"
      @change="changeStep(item)"
      v-model="item.step"
      :disabled="disabled"
      dense
      small
      placeholder="Não definido"
      solo
      flat
      hide-details="true"
      color="black"
    >
    </v-select>
    <v-dialog v-model="showRefuseStep" max-width="490">
      <RefuseStep :toggle="toggleStep" :callback="saveStep" :onCancel="onCancel" />
    </v-dialog>
  </div>
</template>
<script>
/* tslint:disable */
/* eslint-disable */
import { steps } from "@/utils/dps.utils";
import { updateStep } from "@/components/ChangeStep";
import { assign } from "lodash";

export default {
  name: "ChangeStep",
  props: ["item", "beneficiaries"],
  components: {
    RefuseStep: () => import("@/components/ChangeStep/RefuseStep.vue")
  },
  mounted() {
    assign(this.beneficiary, this.item);
    this.disabled = this.item.step === 'Aprovado'
  },
  watch: {
    item(item) {
      assign(this.beneficiary, item);
    }
  },
  data() {
    return {
      disabled: false,
      showRefuseStep: false,
      beneficiary: {}
    };
  },
  methods: {
    changeStep(item) {
      if (item.step === "Recusado") {
        this.showRefuseStep = true;
      } else {
        this.saveStep();
      }
    },
    toggleStep() {
      this.showRefuseStep = !this.showRefuseStep;
    },
    saveStep() {
      const items = this.beneficiaries.filter(beneficiary => beneficiary.cnpj === this.item.cnpj);
      updateStep({ item: this.item, items });
      assign(this.beneficiary, this.item);
    },
    onCancel() {
      assign(this.item, this.beneficiary);
    }
  },
  computed: {
    steps() {
      return steps;
    }
  }
};
</script>
